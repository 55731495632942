<template>
    <div>
        <div class="table_content">

            <!-- 表格内容 -->
            <commonTitle></commonTitle>

            <div class="search">
                <el-form ref="form" :model="form" label-width="80px" :inline="true">
                    <el-form-item label="审批状态:">
                        <el-select v-model="form.region" @change="applyState($event)" placeholder="请选择审批状态" size="mini"
                                   clearable>
                            <el-option label="同意" value="1"></el-option>
                            <el-option label="不同意" value="2"></el-option>
                            <el-option label="审批中" value="3"></el-option>
                            <el-option label="未提交" value="4"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="小区名称:">
                        <el-input v-model="form.name" size="mini" placeholder="请输入小区名称"></el-input>
                    </el-form-item>
                        <button @click.prevent="common()" style="cursor: pointer;">搜索</button>
                        <button @click.prevent="add" style="cursor: pointer;">新建</button>
                </el-form>
            </div>
            <!--表格-->
            <template>
                <el-card>
                    <el-table
                            ref="multipleTable"
                            :data="tableData"
                            tooltip-effect="dark"
                            style="width: 100%"
                            stripe
                            v-loading="loading"
                            element-loading-text="拼命加载中"
                            @selection-change="handleSelectionChange">
                       <!--  <el-table-column
                                type="selection">
                         </el-table-column>-->
                        <el-table-column

                                label="小区名称"
                                show-overflow-tooltip>
                            <template slot-scope="scope">
                                <el-link type="primary" :underline="false"
                                         @click="handleSelect(scope.$index, scope.row)"> {{scope.row.villageName}}
                                </el-link>

                            </template>
                        </el-table-column>
                        <el-table-column
                                property="coveredarea"
                                label="建筑面积"
                                show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                                property="floorspace"
                                label="占地面积"
                                show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                                property="orgName"
                                label="物业单位"
                                show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                                property="settingTime"
                                label="建立时间"
                                sortable
                                show-overflow-tooltip
                                :formatter="formatSettingTime"
                        >
                        </el-table-column>
                        <el-table-column
                                property="actResult"
                                label="审批状态"
                                show-overflow-tooltip
                                :formatter="formatActResult"
                        >
                        </el-table-column>
                        <el-table-column
                                width="220"
                                fixed="right"
                                label="操作">
                            <template slot-scope="scope">
                                <el-button
                                        :disabled="scope.row.actResult!='4'"
                                        size="mini"
                                        type="text"
                                        @click="handleEdit(scope.$index, scope.row)">修改
                                </el-button>
                                <el-button
                                        :disabled="scope.row.actResult!='4'"
                                        size="mini"
                                        type="text"
                                        @click.native.prevent="handleDelete(scope.$index, scope.row,tableData)">删除
                                </el-button>
                                <el-button
                                        size="mini"
                                        type="text"
                                        :disabled="scope.row.actResult!='4'"
                                        @click="subAct(scope.row)">提交审核
                                </el-button>
                                <el-button
                                        size="mini"
                                        type="text"
                                        :disabled="scope.row.actResult!='4'"
                                        @click="uploadfile(scope.row)">导入
                                </el-button>

                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            background
                            style="margin-top: 50px"
                            layout="prev, pager, next"
                            :page-size="15"
                            @current-change="handleCurrentChange"
                            :total="totalNum">
                    </el-pagination>
                </el-card>
            </template>
            <!--分页-->
        </div>

        <!--上传弹框-->
        <el-dialog
                title="数据导入"
                :visible.sync="dialogVisible"
                width="30%"
                style="text-align: center">
            <el-upload
                    accept=".xlsx"
                    class="upload-demo"
                    drag
                    ref="upload"
                    :file-list="fileList"
                    :action="upfileUrl"
                    :limit="1"
                    :auto-upload="false"
                    :before-upload="beforeHistoryUpload"
                    :on-success="handleHistorySuccess"
                    multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传xls/xlsx 格式文件
                    <el-link :underline="false" type="primary" @click="downtemplate">模板下载</el-link>
                </div>
            </el-upload>
            <span slot="footer" class="dialog-footer">
               <el-button @click="dialogVisible = false">取 消</el-button>
               <el-button type="primary" @click="submitHistoryUpload">导入</el-button>
             </span>
        </el-dialog>


    </div>






</template>

<script>
    import qs from "qs"
    import commonTitle from "./commonTitle";

    export default {
        name: "first_table",
        data() {
            return {
                url: this.$Config.base_server,
                form: {
                    name: "",
                    region: '',
                },
                tableData: [],
                multipleSelection: [],
                totalNum: 15,
                loading: false,
                dialogVisible: false,
                upfileUrl:'',
                fileList: [],
                data: {
                    //查询条件
                    access_token: localStorage.getItem("token"),
                    limit: 15,

                }
            }
        },
        components: {
            commonTitle
        },
        mounted() {
            this.common();
            this.upfileUrl=this.url + "/api-public/historyData/kfsimportFile?access_token=" + localStorage.getItem("token");
        },
        methods: {
            common(currentPage = 0) {
                const that = this;
                that.loading = true;

                that.data.page = currentPage;
                that.data.villageName = that.form.name;//搜索框的值
                that.data.actResult = that.form.region;

                $.ajax({
                    url: this.url + "/api-public/opevillage/findAllVillages",
                    data: that.data,
                    contentType: "application/json",
                    type: "get",
                    success: function (res) {
                        that.loading = false;
                        if (res.code == 0) {
                            that.totalNum = res.count;
                            that.tableData = res.data;
                        }
                    },
                    error: function (res) {
                        that.loading = false;
                    }
                })
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleCurrentChange(val) {//当前页数改变
                this.common(val)
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            handleEdit(index, row) {
                //修改
                this.$router.push({name: 'upVillage'});
                localStorage.setItem("villageData", JSON.stringify(row));
            }, handleSelect(index, row) {
                //查看楼幢
                //存储小区数据
                localStorage.setItem("villageData", JSON.stringify(row));
                this.$router.push({name: "deveTower"});
            },
            handleDelete(index, row, tableData) {//删除
                const that = this;
                this.$confirm('您确定删除吗?', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    $.ajax({
                        url: this.url + "/api-public/opevillage/delete/" + row.villageId + "?access_token=" + localStorage.getItem("token"),
                        type: 'delete',
                        success: function (data) {
                            that.loading = false;
                            if (data.resp_code == 0) {
                                tableData.splice(index, 1);
                                //成功
                                that.$message({
                                    type: 'success',
                                    message: '删除成功!'
                                });
                            }
                        }
                    });

                }).catch(() => {
                    that.loading = false;
                });
            },
            deleteTable(id, tableData, index) {
                const that = this;
                const data = {
                    access_token: localStorage.getItem("token")
                }
                $.ajax({
                    url: this.url + "/api-public/opevillage/delete/" + id,
                    data: data,
                    type: "delete",
                    success: function (res) {
                        if (res.code == 0) {
                            tableData.splice(index, 1);
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }
                    }
                })
            }, add() {
                //新增按钮
                this.$router.push({path: "/addVillage"});
            }, applyState(e, currentPage = 0) {
                this.common();
            }, formatActResult(row, column) {
                //控制审批状态
                if (row.actResult == '1') {
                    return '同意';
                } else if (row.actResult == '2') {
                    return '不同意';
                } else if (row.actResult == '3') {
                    return '审批中';
                } else if (row.actResult == '4') {
                    return '未提交';
                }
            }, formatSettingTime(row) {
                //格式化时间
                let date = new Date(row.settingTime);
                let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
                let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                return date.getFullYear() + "-" + month + "-" + day;
            }, formatSubButtom(row) {
                console.log(row.actResult);
                if (row.actResult == 4) {
                    //草稿状态显示提交审核按钮
                }
            }, subAct(row) {
                const that = this;
                that.row = row;
                this.$confirm('您确定提交审核吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.send();
                }).catch(() => {
                });
            },
            send() {
                var that = this;
                that.loading = true;
                //查询是否存在楼栋户室，若不存在则不让提交审
                $.ajax({
                    url: that.url + "/api-public/opevillage/submitVillage?access_token=" + localStorage.getItem("token"),
                    data: {businessId: that.row.villageId, actKey: 'village_flow'},
                    type: 'post',
                    success: function (res) {
                        that.loading = false;
                        if (res.resp_code == 0) {
                            that.common();
                            that.$message({
                                type: 'success',
                                message: '流程提交成功！'
                            });
                        } else {
                            that.$message({
                                type: 'error',
                                message: res.resp_msg
                            });
                        }


                            }
                        });
                    },
            uploadfile(row){
                //数据导入...
                const  that=this;
                that.row=row;
                const upfileUrl=that.upfileUrl;
                const villageId=that.row.villageId
                this.upfileUrl=this.url + "/api-base/opevillage/importFile?villageId="+villageId+"&access_token=" + localStorage.getItem("token");

                //this.upfileUrl=this.url + "/api-public/historyData/kfsimportFile?villageId="+villageId+"&access_token=" + localStorage.getItem("token");
                this.dialogVisible = true;

            },
            downtemplate(){
                //下载导入模板
                var that = this;
                location.href=that.url + "/api-public/historyData/import/kfstemplate?access_token=" + localStorage.getItem("token");
            },
            beforeHistoryUpload(file) {
                //上传前进行校验格式
                const Xls = file.name.split('.');
                if (Xls[1] === 'xlsx') {
                    this.dialogVisible = false;
                    this.loading = true;
                    return file;
                } else {
                    this.$message.error('上传文件只能是xlsx 格式')
                    return false
                }

            },
            submitHistoryUpload() {
                //确定上传按钮
                this.$refs.upload.submit();
            },
            handleHistorySuccess(response, file, fileList) {
                if (response.resp_code == 0) {
                    //上传成功回调
                    this.$message({
                        type: 'success',
                        message: response.resp_msg,
                        duration: 2000,
                        dangerouslyUseHTMLString: true
                    });
                    //关闭上传弹框
                    this.dialogVisible = false;
                    this.loading = true;
                    this.common();
                    this.$refs['upload'].clearFiles();
                }
                if (response.resp_code == 1) {
                    //上传成功回调
                    this.$message({
                        type: 'error',
                        message: response.resp_msg,
                        dangerouslyUseHTMLString: true
                    });
                    //关闭上传弹框
                    this.dialogVisible = false;
                    this.loading = true;
                    this.common();
                    this.$refs['upload'].clearFiles();
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/table/first_table.css";

    .el-button {
        padding: 5px 5px;
    }

</style>